.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.model-select {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: lighter;
    font-size: 20px;
}

.model-select>* {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.05));
    font-variant: small;
    border: 0.5px solid rgb(112, 110, 110);
    color: black;
    min-width: 200px;
    text-align: center;
    padding: 10px;
    height: 50px;
    max-height: 50px;
    overflow: hidden;
}

.model-select>*:hover {
    background: black;
    color: white;
    text-decoration: none;
}

.back-button {
    position: absolute;
    z-index: 1000000;
    left: 10px;
    top: 10px;
    border: 1px solid black;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
}

.back-button:hover {
    cursor: pointer;
    background: black;
    color: white;
}

.content-box-go-back {
    color: rgb(58, 57, 57);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.32);
    z-index: 100000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-box-go-back>* {
    /*     border: 1px solid; */
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.38);
    background: white;
    padding: 20px;
}

.atention-title {
    text-align: center;
    font-size: 20px;
}

.atention-details {
    font-size: 12px;
}

.response-box-back {
    display: flex;
    margin: 15px 0 0 0;
    justify-content: space-around;
}

.response-box-back>* {
    border: 1px solid black;
    padding: 5px 30px;
    cursor: pointer;
}

.response-box-back>*:hover {
    background: black;
    color: white;
}