.main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    /* height: 100%; */
}

.shoe-container {
    display: grid;
    place-items: center;
}

.stepper-container {
    width: 500px;
    /* display: grid;
    place-items: center; */
}

.button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.color-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.centered-container {
    display: grid;
    place-items: center;
}

.text-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.control-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.step-button {
    width: 100px;
}

.upload-button {
    width: 100%;
}

.theme-button {
    border-radius: 2px;
    background-color: #a01e64;
    color: white;
    border: 1px solid white;
}


/* .theme-button:disabled {
    background-color: #a30e5e;
    color: white;
    border: 1px solid white;
} */

.theme-button:hover {
    background-color: white;
    color: black;
    border: 1px solid #a01e64;
}


/* .theme-button:active {
    background-color: white;
    color: black;
    border: 1px solid #a01e64;
} */

.theme-button:focus {
    outline: none;
}

.input-box {
    border-radius: 0px;
    outline: none !important;
    outline-width: 0 !important;
}

.input-box:focus {
    border-color: #ddd;
    border-style: dotted;
    outline: none !important;
    outline-width: 0 !important;
}

.input-text {
    width: 100%;
}

.button-txt {
    width: 100%;
}

.color-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 1px #ddd;
    outline: none;
}

.color-button:focus {
    outline: none;
}

.color-button-selected {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 2px #a01e64;
    filter: drop-shadow(0 0 3px #83104d) invert(0%);
    outline: none;
}

/* .color-button-selected::after {
    content: "\229A";
    color: #a01e64;
    filter: drop-shadow(0 0 1px white) invert(0%);
    top: -1px;
    position: relative;
} */

.color-button-selected:focus {
    outline: none;
}

.label {
    background-color: aquamarine;
}

.main-container {
    display: flex;
    flex-direction: row;
}

.draggable-item {
    cursor: move
}

.not-draggable-item {
    cursor: default
}

.no-padding {
    padding: 0
}

.color-input {
    /* width: 30px; */
    height: 100%
}

.theme-option:hover {
    background-color: #a01e64;
    color: white;
}

.row {
    padding-top: 5px;
    padding-bottom: 5px;
}

.accordion-cell {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    border: none;
    /* border-top: 3px solid lightgrey; */
    border-radius: 0px;
    margin-bottom: '1rem';
    background: rgba(255, 255, 255, 0.6);
    margin-top: 1px;
    letter-spacing: 1px;
    color: gray;
    font-size: 14px;
}

.card-body {
    background: rgb(255, 255, 255);
}

.disabled {
    cursor: not-allowed;
    text-decoration: line-through;
}

.disabled:hover::after {
    position: absolute;
    content: "Va rugam sa completati datele din sectiunea 'Masuri'";
    left: 0%;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(38, 35, 35, 0.905);
    color: tomato;
    font-size: 14px;
    text-align: center;
    /* margin: auto; */
    align-items: center;
    display: flex;
    justify-content: center;
}

.final-zone {
    font-size: 13px;
}

.accordion-cell .card-header {
    text-transform: uppercase;
    font-weight: 300;
}

.solved::before {
    content: "\2713";
    position: absolute;
    right: 10px;
    color: green;
}

.opened-cell {
    font-weight: 500;
    color: rgb(58, 57, 57) !important;
}


/* .last-accordion-cell {
    border-bottom: 3px solid lightgrey;
}

.accordion-cell-header {
    cursor: pointer;
    background-color: transparent;
    border: none;
} */


/* Cristi's development */

@media screen and (min-width: 767px) {
    .main-container {
        display: flex;
        justify-content: space-around;
        align-content: center;
        align-items: center;
    }
}

body {
    background: #D3CCE3;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #E9E4F0, #D3CCE3);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    height: 100vh;
}

canvas {
    border-radius: 10px;
}

.row label {
    font-size: 13px;
    color: gray;
}

.row label+input::placeholder {
    color: lightgray;
}

#measurement-zone input {
    font-size: 11px;
}

.error-message {
    color: tomato;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: none;
}


/* END Cristi zone */